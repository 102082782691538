






































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import SidebarPurchase from '@/components/sidebar-purchase.vue'; // @ is an alias to /src
import AccountService from '@/services/account-service';
import BuiltinService from '@/services/buitin-service';

@Component({
  components: {
    Header,
    SidebarPurchase
  },
})
export default class CashPurchaseVoucher extends Vue {

  public items = [{ code : '', title: '', credit : '', project : '', reference: '', description: ''}];
  account_options = [];
  branch_options = [];
  project_options = [];
  trans :any = {};

  getaccounts(){
    AccountService.getchartchildren()
      .then((response) => {
        this.account_options = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
      BuiltinService.getbranch()
      .then((response) => {
        this.branch_options = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
      BuiltinService.getproject()
      .then((response) => {
        this.project_options = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  addRow(){      
       this.items.push({ code : '', title: '', credit : '', project : '', reference: '', description: ''})
  }
  save(){
    let obj:any = {};
    obj['date'] = new Date();
    console.log(this.items);
  }
  mounted() {
    this.getaccounts();
  }
}
